.backdrop {
    background: rgba(0, 0, 0, 0.6);
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
}
.menu-popup {
    width: 90%;
    max-width: 1200px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    overflow: hidden;
}
.menu-popup-grid {
    display: grid;
    grid-template-columns: 1fr 5fr;
    height: 510px;
}
.menu-popup-grid .menu-sidebar {
    background: #2d3a4c;
}
.menu-popup-grid .menu-sidebar .menu-title {
    padding: 25px 0 10px;
    font-size: 18px;
    line-height: 30px;
    font-weight: 700;
    text-align: center;
    color: #fff;
}
.menu-popup-grid .menu-content {
    position: relative;
    background: #fff;
    height: 510px;
    overflow-y: auto;
}
.menu-popup-grid .menu-sidebar ul li {
    color: #f5f5f5;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s ease;
    position: relative;
    display: flex;
    align-items: center;
    height: 44px;
    line-height: 44px;
    padding-left: 25px;
}
.menu-popup-grid .menu-sidebar ul li.active {
    background: #fd4e66;
    color: #fff;
}
.menu-popup-grid .menu-sidebar ul li span {
    display: block;
    color: #fff;
    line-height: 1.2em;
    font-size: 16px;
}
.menu-popup-grid .menu-sidebar ul li i {
    display: inline-block;
    width: 25px;
    height: 25px;
    vertical-align: middle;
    background-image: url(../../image/nav.png);
    position: relative;
    top: 2px;
    margin-right: 3px;
}
.icon_securityCenter {
    background-position: -148px -84px;
}
.icon_depositv2 {
    background-position: -316px -15px;
}
.icon_withdraw {
    background-position: -14px -49px;
}
.icon_personalHistoryCopy {
    background-position: -182px 105px;
}
.icon_transactionHistory {
    background-position: -274px -53px;
}
.icon_personalProfit {
    background-position: -57px -84px;
}
.icon_rewardCenter {
    background-position: -106px -49px;
}
.icon_messageV2 {
    background-position: -148px -48px;
}
.icon_bonusDetails {
    background-position: -182px -85px;
}
.icon_referral {
    background-position: -274px -81px;
}
.icon_agentAnnouncement {
    background-position: -274px -53px;
}
.icon_agentTeamOverview {
    background-position: -14px -49px;
}
.close-popup {
    background: #ccc;
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 5px;
    border-radius: 99%;
    height: 30px;
    width: 30px;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
    z-index: 3;
}
.close-popup svg {
    width: 20px;
    height: 20px;
    font-size: 20px;
    fill: #fff;
}
